<template>
    <div class="card">
        <div class="card-header">
            <div class="d-flex">
                <h5 class="card-title flex-grow-1 mb-0">{{ $t('client') }}</h5>
                <div class="flex-shrink-0">
                    <a class="link-secondary">{{ $t('Profile') }}</a>
                </div>
            </div>
        </div>
        <div class="card-body">
            <ul class="list-unstyled mb-0 vstack gap-3">
                <li>
                    <div class="d-flex align-items-center">
                        <!-- <div class="flex-shrink-0">
                            <img src="assets/images/users/avatar-3.jpg" alt="" class="avatar-sm rounded">
                        </div> -->
                        <div class="flex-grow-1 ms-3">
                            <h6 class="fs-14 mb-1">Олег</h6>
                            <p class="text-muted mb-0">клиент/покупатель</p>
                        </div>
                    </div>
                </li>
                <li><i class="ri-mail-line me-2 align-middle text-muted fs-16"></i>oleg@gmail.com</li>
                <li><i class="ri-phone-line me-2 align-middle text-muted fs-16"></i>{{ form.client }}</li>
            </ul>
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <div class="d-flex">
                <h5 class="card-title flex-grow-1 mb-0">{{ $t('ShippingAddress') }}</h5>
            </div>
        </div>
        <div class="card-body">
            <ul class="list-unstyled mb-0 vstack gap-3">
                <li><i class="ri-mail-line me-2 align-middle text-muted fs-16"></i>Адреса та всі інші дані</li>
                <!-- <li><i class="ri-phone-line me-2 align-middle text-muted fs-16"></i>{{ form.client }}</li> -->
            </ul>
        </div>
    </div>
    <div class="card">
        <div class="card-header">
            <div class="d-flex">
                <h5 class="card-title flex-grow-1 mb-0">{{ $t('PaymentDetails') }}</h5>
            </div>
        </div>
        <div class="card-body">
            <ul class="list-unstyled mb-0 vstack gap-3">
                <li><i class="ri-mail-line me-2 align-middle text-muted fs-16"></i>Рахунок, оплата та інші дані</li>
                <!-- <li><i class="ri-phone-line me-2 align-middle text-muted fs-16"></i>{{ form.client }}</li> -->
            </ul>
        </div>
    </div>
</template>

<script>

export default{
    props: ['obj'],
    data(){
        return{
            form: ''
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.obj
        }
    }
}

</script>