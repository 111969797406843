<template>
    <div class="card">
        <div class="card-header">
            <div class="d-sm-flex align-items-center">
                <h5 class="card-title flex-grow-1 mb-0">{{ $t('orderStatus') }}</h5>
                <!-- <div class="flex-shrink-0 mt-2 mt-sm-0">
                    <a href="javasccript:void(0;)" class="btn btn-soft-info btn-sm mt-2 mt-sm-0"><i class="ri-map-pin-line align-middle me-1"></i> Change Address</a>
                    <a href="javasccript:void(0;)" class="btn btn-soft-danger btn-sm mt-2 mt-sm-0"><i class="mdi mdi-archive-remove-outline align-middle me-1"></i> Cancel Order</a>
                </div> -->
            </div>
        </div>
        <div class="card-body">
            <div class="profile-timeline">
                <div class="accordion accordion-flush" id="accordionFlushExample">
                    <div class="accordion-item border-0">
                        <div class="accordion-header" id="headingOne">
                            <a class="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 avatar-xs">
                                        <div :class="`avatar-title bg-${form.statusDelivery[0].status ? 'success' : 'light text-success'} rounded-circle`">
                                            <i class="ri-shopping-bag-line"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="fs-15 mb-0 fw-semibold">{{ $t('OrderReceived') }} - <span class="fw-normal">{{ form.orderDate}}</span></h6>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapseOne" :class="`accordion-collapse collapse ${form.statusDelivery[0].status ? 'show': ''}`" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body ms-2 ps-5 pt-0">
                                <h6 class="mb-1">{{ $t('OrderReceived') }}.</h6>
                                <p class="text-muted">{{ form.orderDate }}</p>

                                <h6 class="mb-1">{{ $t('managerProcessedOrder') }}</h6>
                                <p class="text-muted mb-0">{{ form.statusDelivery[0].dateProcessing + " " + form.statusDelivery[0].timeProcessing }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item border-0">
                        <div class="accordion-header" id="headingTwo">
                            <a class="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 avatar-xs">
                                        <div :class="`avatar-title bg-${form.statusDelivery[1].status ? 'success' : 'light text-success'} rounded-circle`">
                                            <i class="mdi mdi-gift-outline"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="fs-15 mb-1 fw-semibold">{{ $t('Packaging') }} - 
                                            <span class="fw-normal">{{ form.statusDelivery[1].date }}</span>
                                        </h6>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapseTwo" :class="`accordion-collapse collapse ${form.statusDelivery[1].status ? 'show': ''}`" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body ms-2 ps-5 pt-0">
                                <h6 class="mb-1">{{ $t('NewPostReceivedGoods') }}</h6>
                                <p class="text-muted mb-0">{{ form.statusDelivery[1].date + " " + form.statusDelivery[1].time }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item border-0">
                        <div class="accordion-header" id="headingThree">
                            <a class="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 avatar-xs">
                                        <div :class="`avatar-title bg-${form.statusDelivery[2].status ? 'success' : 'light text-success'} rounded-circle`">
                                            <i class="ri-truck-line"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="fs-15 mb-1 fw-semibold">{{ $t('Delivery') }} - <span class="fw-normal">{{ form.statusDelivery[2].date }}</span></h6>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapseThree" :class="`accordion-collapse collapse ${form.statusDelivery[2].status ? 'show': ''}`" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body ms-2 ps-5 pt-0">
                                <h6 class="fs-14">{{ $t('ParcelNumber') }} - {{ form.ttn ? form.ttn : '-' }}</h6>
                                <h6 class="mb-1">{{ $t('YourItemShipped') }}</h6>
                                <p class="text-muted mb-0">{{ form.statusDelivery[2].date + " " + form.statusDelivery[2].time }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item border-0">
                        <div class="accordion-header" id="headingFive">
                            <a class="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFile" aria-expanded="false">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 avatar-xs">
                                        <div :class="`avatar-title bg-${form.statusDelivery[3].status ? 'success' : 'light text-success'} rounded-circle`">
                                            <i class="mdi mdi-package-variant"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h6 class="fs-14 mb-0 fw-semibold">{{ $t('delivered') }} {{ form.statusDelivery[3].date }}</h6>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div id="collapseThree" :class="`accordion-collapse collapse ${form.statusDelivery[3].status ? 'show': ''}`" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body ms-2 ps-5 pt-0">
                                <h6 class="mb-1">{{ $t('customerReceivedGoods') }}</h6>
                                <p class="text-muted mb-0">{{ form.statusDelivery[3].date + " " + form.statusDelivery[3].time }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end accordion-->
            </div>
        </div>
    </div>

</template>

<script>
export default{
    props: ['obj'],
    data(){
        return{
            form: ''
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.obj
        }
    }
}
</script>