<template>
    <PageHeader :title="title" /> 

    <tablecustom 
      @search="searchB"  
      :columns="columns" 
      :rows="rows" 
      @open="open"
      @getdata="getdata"
    />

    <viewBox 
        v-if="showView"
        @close="closeModal"
        :objCard="objCard"
    />

</template>

<script>
import PageHeader from "@/components/page-header";
import tablecustom from '@/components/globaltable/index';
import viewBox from './view/index'
import { mutateTypePay, mutateStatusDelivery } from '@/usabilityScripts/globalMutate.js'

export default{
    components: {
        PageHeader,
        tablecustom,
        viewBox
    },
    data(){
        return {
            title: this.$t('Orders'),
            showView: false,
            objCard: '',
            form: '',
            rows: [
                {
                    orderId: '1',
                    client: '380636666666',
                    products: [ 
                        {
                            title: 'Принтер Xprinter',
                            price: '3500',
                            quantity: '1',
                        },
                        {
                            title: 'Планшет Samsung',
                            price: '12000',
                            quantity: '1',
                        },
                    ],
                    orderDate: '2023-05-01',
                    orderTime: '17:20',
                    sum: '15500',
                    payMethod: 'invoice',
                    deliveryStatus: 'delivered',
                    ttn: '897897298989992',
                    statusDelivery: [
                        {
                            type: 'orderreceived',
                            date: '2023-05-01',
                            time: '17:20',
                            dateProcessing: '2023-05-01',
                            timeProcessing: '17:50',
                            status: true
                        },
                        {
                            type: 'packaging',
                            date: '2023-05-02',
                            time: '09:20',
                            status: false,
                        },
                        {
                            type: 'delivery',
                            date: '2023-05-02',
                            time: '12:00',
                            status: false,
                        },
                        {
                            type: 'delivered',
                            date: '2023-05-03',
                            time: '11:00',
                            status: false,
                        },
                    ]
                }
            ],
            columns: [
                {
                    name: "ID",
                    text: "orderId",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('client'),
                    text: "client",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('date_time'),
                    text: "orderDate",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('Sum'),
                    text: "sum",
                    align: "left",
                    status: true
                },
                {
                    name: 'Спосіб оплати',
                    text: "payMethod",
                    align: "left",
                    status: true,
                    mutate: (item) => item ? this.mutatePays(item).name : '-',
                    mutateClass: (item) => item ? this.mutatePays(item).color : ''
                },
                {
                    name: "Статус доставки",
                    text: "deliveryStatus",
                    align: "left",
                    status: true,
                    mutate: (item) => item ? this.mutateDelivery(item).name : '-',
                    mutateClass: (item) => item ? this.mutateDelivery(item).color : ''
                },
            ]
        }
    },
    methods: {
        getdata(){

        },
        open(e){
            this.objCard = e;
            this.showView = true;
        },
        closeModal(){
            this.showView = false;
            // this.getdata()
        },
        mutatePays(e){
            return mutateTypePay(e);
        },
        mutateDelivery(e){
            return mutateStatusDelivery(e);
        }
    }
}
</script>