<template>
    <div class="card">
        <div class="card-header">
            <div class="d-flex align-items-center">
                <h5 class="card-title flex-grow-1 mb-0">{{ $t('Order') }} #{{ form.orderId }}</h5>
                <div class="flex-shrink-0">
                    <a disabled class="btn btn-success btn-sm"><i class="ri-download-2-fill align-middle me-1"></i> Invoice</a>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive table-card">
                <table class="table table-nowrap align-middle table-borderless mb-0">
                    <thead class="table-light text-muted">
                        <tr>
                            <th scope="col">{{ $t('Product') }}</th>
                            <th scope="col">{{ $t('price') }}</th>
                            <th scope="col">{{ $t('Quantity') }}</th>
                            <th scope="col" class="text-end">{{ $t('Sum') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="item in form.products" :key="item">
                            <tr>
                                <td>
                                    <div class="d-flex">
                                        <div class="flex-grow-1 ms-3">
                                            <h5 class="fs-15"><a class="link-primary">{{ item.title }}</a></h5>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ item.price }}</td>
                                <td>{{ item.quantity }}</td>
                                <td class="fw-medium text-end">
                                    {{ (item.quantity * item.price).toFixed(0) }}
                                </td>
                            </tr>
                        </template>

                        <tr class="border-top border-top-dashed">
                            <td colspan="3"></td>
                            <td colspan="2" class="fw-medium p-0">
                                <table class="table table-borderless mb-0">
                                    <tbody>
                                        <tr class="border-top border-top-dashed">
                                            <th scope="row">{{ $t('Sum') }} :</th>
                                            <th class="text-end">{{ form.sum }}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>


</template>

<script>
export default{
    props: ['obj'],
    data(){
        return{
            form: ''
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.obj
        }
    }
}
</script>