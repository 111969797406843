<template>
    <modal :title="$t('Order')" :stylebody="'var(--vz-body-bg)'">
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body >
            <b-row>
                <b-col lg="3">
                    <leftBlock :obj="objCard"/>
                </b-col>
                <b-col lg="9">
                    <rightBlock :obj="objCard"/>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <!-- button -->
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal'
import leftBlock from './left/index'
import rightBlock from './right/index'

export default{
    props: ['objCard'],
    components: {
        modal,
        leftBlock,
        rightBlock
    },
    data(){
        return{
            form: ''
        }
    }
}
</script>