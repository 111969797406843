<template>

    <order :obj="obj" />

    <statusOrder :obj="obj" />

</template>

<script>
import order from './order'
import statusOrder from './status'

export default{
    props: ['obj'],
    components: {
        order,
        statusOrder
    },
    data(){
        return{
            form: ''
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.obj
        }
    }
}
</script>